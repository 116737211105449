/**
 * This file is only used for the browser version of `same-origin`.
 * Used to bring down the size of the browser bundle.
 */
'use strict';

var regex = /^(?:(?:(?:([^:\/#\?]+:)?(?:(?:\/\/)((?:((?:[^:@\/#\?]+)(?:\:(?:[^:@\/#\?]+))?)@)?(([^:\/#\?\]\[]+|\[[^\/\]@#?]+\])(?:\:([0-9]+))?))?)?)?((?:\/?(?:[^\/\?#]+\/+)*)(?:[^\?#]*)))?(\?[^#]+)?)(#.*)?/;

module.exports = {
    regex: regex,
    parse: function(url) {
        var match = regex.exec(url);
        if (!match) {
            return {};
        }

        return {
            protocol: (match[1] || '').toLowerCase() || undefined,
            hostname: (match[5] || '').toLowerCase() || undefined,
            port: match[6] || undefined
        };
    }
};