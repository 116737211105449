"use strict";

//  Since `@sanity/client` doesn't offer ESM exports (yet) const {filter} = require('rxjs/operators') will cause the the whole of rxjs to be included in the bundle.
//  The internal import paths here is a stop-gap measure and will become less of a problem when @sanity/client export tree-shakeable esm bundles
var _require = require('rxjs/internal/Observable'),
    Observable = _require.Observable;

var _require2 = require('rxjs/internal/operators/filter'),
    filter = _require2.filter;

var _require3 = require('rxjs/internal/operators/map'),
    map = _require3.map;

module.exports = {
  Observable: Observable,
  filter: filter,
  map: map
};